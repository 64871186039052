<template>
  <div>
    <b-modal ref="modalRef" title="About"
      size="xl" 
      header-bg-variant="light" header-text-variant="dark"
      body-bg-variant="light" body-text-variant="dark"
      footer-bg-variant="light" footer-text-variant="dark"
      content-class="shadow" ok-only>
        <b-list-group class="mb-2">
            <b-list-group-item>
                <span class="font-weight-bold">Version</span>
                <pre class="bg-light text-dark mt-2">{{versionInfo}}</pre>
            </b-list-group-item>
            <b-list-group-item>
                <span class="font-weight-bold">License</span>
                <pre class="bg-light text-dark mt-2">{{license}}</pre>
            </b-list-group-item>
        </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'about',
  components: {
  },
  data() {
    return {
        license: '',
        versionInfo: '',
    }
  },
  computed: {
  },
  methods: {
    show() {
      this.$refs['modalRef'].show()
      axios.get(`${process.env.BASE_URL}VERSION.txt?v=${Date.now()}`)
      .then(response => {
        this.versionInfo = response.data
      })
      .catch(err => {
        this.$log("Unable to get VERSION.txt: "+err.message)
      })
      
      axios.get(`${process.env.BASE_URL}LICENSE.txt?v=${Date.now()}`)
      .then(response => {
        this.license = response.data
      })
      .catch(err => {
        this.$log("Unable to get LICENSE.txt: "+err.message)
      })
    }
  }
};
</script>
<style scoped>
a {
  font-size: 0.5rem;
}
a:link {
    color: white;
}
a:visited {
    color: white;
}
a:hover {
    color: white;
}
pre { 
  background-color: gray;
  max-height: 500px;
  overflow-y: auto;
  padding: 2px;
  white-space: pre-wrap;
}
</style>